import { useEffect, useState } from "react";
import Axios from "axios";
import Config from "../Config";
import Heart from "../img/heart.png"
import Time from "../img/timer.png"
import { useAppContext } from "../context/appContext";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SurveyHistory = () => {
  const [history, setHistory] = useState(null);
  const { setSurveyHistory } = useAppContext();
  const param = useParams();

  useEffect( () => {
    async function fetchData() {
      try {
         const { data: { data } } = await Axios.get(`${Config.apiUrl}/api/v1/survey-histories?${param?.user ? 'user_id='+param.user: '' }`);
          setHistory(data);
          setSurveyHistory(data);
      } catch (error) {
        toast('Failed to fetch survey histories. Please try again later.', {
          type: 'error',
        });
      }
    }
    fetchData();
  }, [param, setSurveyHistory]);
  return (
    <div className="h-screen pt-32 px-4">
        {history?.length === 0 && <div>
          <h3>Geen resultaten gevonden</h3>
          </div>}
        {history?.map((item, index) => 
        <NavLink className="relative border-2 border-black rounded-2xl p-4 mb-4 cursor-pointer flex justify-between items-center" key={`preview-${index}`} to={`/app/surveys/${item._id}/preview?${param?.user ? 'user_id='+param.user: '' }`}>
            <p className="text-black font-medium text-sm md:text-lg">{index + 1}. Enquêteresultaat - {new Date(item.createdAt).toLocaleDateString()}</p>
            <img src={item.answer.type === 'quick' ? Time : Heart} alt="question type" className="w-20 h-20 absolute right-0 -bottom-5"  />
            <span className="absolute text-xs right-2 top-1">{new Date(item.createdAt).toLocaleDateString() +' '+ new Date(item.createdAt).toLocaleTimeString()}</span>
        </NavLink>
        )}
    </div>
  )
}
export default SurveyHistory