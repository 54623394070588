import Checker from "../../img/checker.svg";
import { useHistory } from "react-router-dom";

const SurveyCard = ({text, image, bgColor, list, category, isDisabled}) =>  {
    let history = useHistory();
    console.log("list", list);
    return <div className={`flex flex-col justify-center items-center space-y-28 ${isDisabled && 'filter grayscale pointer-events-none select-none'}`}>
    <div className={`card h-[650px] w-[400px] ${isDisabled && category === 'extensive' ? 'bg-orange-300' : bgColor}`} 
         onClick={() => history.push(`/app/surveys/${category}`)}
         style={{
           backgroundColor: 'white',
           border: '3px solid rgb(0, 107, 0)',
           borderRadius: '20px',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           flexDirection: 'column'
         }}>

        <img src={image} alt="timer" className={`isDisabled ? "opacity-60" : "" w-[150px]`} />
        <button 
          className="text-xl mt-2 font-medium py-2 px-4" 
          style={{
            backgroundColor: '#f2c83b',
            border: '1px solid black',
            borderRadius: '5px',
            width: '80%',
            cursor: 'pointer'
          }}
          onClick={() => history.push(`/app/surveys/${category}`)}
        >
          {text}
        </button>        <div className="card bg-transparent shadow-none rounded-2xl md:min-h-[350px] h-max w-[350px] p-4 space-y-4" onClick={() => history.push(`/app/surveys/${category}`)}>
            {list?.map((item, _index) => <div className="flex items-start space-x-2" key={_index}>
          <img src={Checker} alt="list checker" />
          <h4>{item}</h4>
        </div>)}
        </div>

    </div>


</div>
}

export default SurveyCard;
