import React from "react";
import { useAuth0 } from "../util/react-auth0-wrapper";

export const UserContext = React.createContext();

export const UserContextProvider = props => {
  const { user } = useAuth0();
  return (
    <UserContext.Provider
      value={{
        "profile": user
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
