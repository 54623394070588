import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ value, showPercentageValue = true }) => {
  const actualValue = value / 10;
  const valueToDisplay = actualValue === 0 ? '0' : actualValue;
  return (
    <CircularProgressbar
      value={value}
      text={showPercentageValue ? `${value}%` : valueToDisplay}
      strokeWidth={10}
      styles={buildStyles({
        textColor: 'black',
        pathColor: '#052e16',
      })}
    />
  );
};
export default CircularProgress;
