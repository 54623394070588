import React, { useState } from 'react';
import CircularProgress from '../survey/CircularProgress';

const Accordion = ({
  title = '',
  defaultOpen = false,
  score = '',
  children,
}) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  return (
    <div className="relative antialiased">
      <main className="overflow-hidden relative">
        <div
          className="w-full p-6"
          style={{
            backgroundColor: 'white',
            border: '2px solid rgb(0, 107, 0)',
            borderRadius: '20px',
          }}
        >
          <div className="divide-y divide-slate-200">
            <div>
              <h2>
                <button
                  id="faqs-title-07"
                  type="button"
                  className="flex items-center justify-between w-full text-left"
                  onClick={() => setExpanded(!expanded)}
                  aria-expanded={expanded}
                  aria-controls="faqs-text-07"
                >
                  <div className="flex justify-between items-center w-full">
                    <p className="capitalize font-bold ">{title}</p>
                    {score && (
                      <div className="w-12 h-12">
                        <CircularProgress
                          value={score}
                          showPercentageValue={false}
                        />
                      </div>
                    )}
                  </div>

                  <span className="w-[68px] flex justify-center">
                    <svg
                      className="shrink-0"
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#052e16"
                    >
                      <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center transition duration-200 ease-out ${
                          expanded ? '!rotate-180' : ''
                        }`}
                      />
                      <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                          expanded ? '!rotate-180' : ''
                        }`}
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <div
                id="faqs-text-07"
                role="region"
                aria-labelledby="faqs-title-07"
                className={`grid overflow-hidden transition-all duration-500 ease-in-out ${
                  expanded
                    ? 'grid-rows-[1fr] opacity-100'
                    : 'grid-rows-[0fr] opacity-0'
                }`}
              >
                <div className="overflow-hidden">
                  <div className="pb-3">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Accordion;
