export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function findLocalItems(query) {
  let i;
  let results = [];
  for (i in localStorage) {
    if (localStorage.hasOwnProperty(i)) {
      if (i.match(query) || (!query && typeof i === 'string')) {
        let value = JSON.parse(localStorage.getItem(i));
        results.push({ key: i, val: value });
      }
    }
  }
  return results;
}

export function generatePagination(current, limit, total) {
  const totalPages = Math.ceil(total / limit);

  if (totalPages === 1) return [1];

  const center = [current - 2, current - 1, current, current + 1, current + 2];
  const filteredCenter = center.filter((p) => p > 1 && p < totalPages);
  const includeThreeLeft = current === 5;
  const includeThreeRight = current === totalPages - 4;
  const includeLeftDots = current > 5;
  const includeRightDots = current < totalPages - 4;

  if (includeThreeLeft) filteredCenter.unshift(2);
  if (includeThreeRight) filteredCenter.push(totalPages - 1);

  if (includeLeftDots) filteredCenter.unshift('...');
  if (includeRightDots) filteredCenter.push('...');

  return [1, ...filteredCenter, totalPages];
}

export const chartOptions = {
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '25px',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['transparent'],
  },
  colors: ['#052e16', '#d6d6d6'],
  yaxis: {
    title: {
      show: true,
      title: '%',
    },
    labels: {
      formatter: (val) => {
        return val + '%';
      },
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    enabled: false,
  },
};
