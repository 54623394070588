// // eslint-disable-next-line
// export default {
//     apiUrl: process.env.API_URL || 'http://localhost:3000',
//     auth: {
//         "domain": process.env.AUTH0_DOMAIN || "dev-i110z6ryo20kqw6u.eu.auth0.com",
//         "clientId": process.env.AUTH0_CLIENTID || "Ud6lTabKUU41EtI9QSpPLqVrDz01qltD",
//         "audience": process.env.AUTH0_AUDIENCE || "https://dev-i110z6ryo20kqw6u.eu.auth0.com/api/v2/"
//     },
//     billingEnabled: false
// }

// eslint-disable-next-line
export default {
    apiUrl: 'https://veerkracht-api.erwelzijn.nl',
    auth: {
        "domain": process.env.AUTH0_DOMAIN || "dev-i110z6ryo20kqw6u.eu.auth0.com",
        "clientId": process.env.AUTH0_CLIENTID || "Ud6lTabKUU41EtI9QSpPLqVrDz01qltD",
        "audience": process.env.AUTH0_AUDIENCE || "https://dev-i110z6ryo20kqw6u.eu.auth0.com/api/v2/"
    },
    billingEnabled: false
}
