import React from 'react';

const Tag = ({ label, color = 'black', onRemove }) => {
  const colorClasses = {
    blue: 'bg-blue-100 text-blue-700 hover:text-blue-900',
    red: 'bg-red-100 text-red-700 hover:text-red-900',
    green: 'bg-green-100 text-green-700 hover:text-green-900',
    black: 'bg-black text-white hover:text-white',
  };

  const classes = colorClasses[color] || colorClasses['blue'];

  return (
    <div
      className={`inline-flex items-center px-3 py-1 rounded-full ${classes}`}
    >
      <span>{label}</span>
      {onRemove && (
        <button
          type="button"
          onClick={onRemove}
          className="ml-2 focus:outline-none"
        >
          &times;
        </button>
      )}
    </div>
  );
};

export default Tag;
