import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { toast } from 'react-toastify';
import Axios from 'axios';
import Config from '../Config';
import { useHistory } from 'react-router-dom';
import { findLocalItems } from './util';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [appUser, setAppUser] = useState(null);
  const [auth0Client, setAuth0] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client({...initOptions, cacheLocation: 'localstorage'});
        setAuth0(auth0FromHook);
        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          if (appState.path) {
            history.push(appState.path);
          } else {
            history.push('/app');
          }
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        if (isAuthenticated) {
          const token = await auth0FromHook.getTokenSilently();
          localStorage.setItem('token', token);

          Axios.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
          });

          const userProfile = await auth0FromHook.getUser();
          setUser(userProfile);

          const userData = await Axios.get(`${Config.apiUrl}/api/v1/users/me`);
          let user = userData.data?.data;
          if (user?.role?.name === 'super admin') {
            if (!localStorage.getItem('sa-organization')) {
              history.push('/select-organization');
            } else {
              let organization = JSON.parse(localStorage.getItem('sa-organization') || "{}");
              user.organization = organization;
              try {
                await Axios.get(`${Config.apiUrl}/api/v1/organizations/${organization?._id}`);
              } catch (error) {
                if (error?.response?.status === 404) {
                  localStorage.removeItem('sa-organization');
                  user.organization = null;
                  history.push('/select-organization');
                }
              }
            }
          }
          setAppUser(user);
          setIsAuthenticated(isAuthenticated);
        }
        setLoading(false);
      } catch (error) {
        if (error?.response?.status === 400) {
          localStorage.removeItem('token');
          localStorage.removeItem('sa-organization');
          let auth0Key = findLocalItems("@@auth0spajs@@")[0];
          
          if(auth0Key) {
            localStorage.removeItem(auth0Key.key);
          }
        }

        toast('Unable to login. Please try again later.', {
          type: 'error',
        });
        
        setLoading(false);
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []); // Run once on mount

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      toast('Unable to login. Please try again later.', {
        type: 'error',
      });
    } finally {
      setPopupOpen(false);
    }
    const userProfile = await auth0Client.getUser();
    setUser(userProfile);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    try {
      await auth0Client.handleRedirectCallback();
      const userProfile = await auth0Client.getUser();
      setUser(userProfile);
      setIsAuthenticated(true);
    } catch (error) {
      toast('Unable to login. Please try again later.', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const logout = (...p) => {
    auth0Client?.logout(...p);
    localStorage.removeItem('token');
    localStorage.removeItem('sa-organization');
    let auth0Key = findLocalItems("@@auth0spajs@@")[0];
    
    if(auth0Key) {
      localStorage.removeItem(auth0Key.key);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        appUser,
        setAppUser,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: () => {
          auth0Client?.loginWithRedirect({
            appState: { path: window.location.pathname },
          });
        },
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p) => logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export const withAuth0 = (Component) => {
  return function WrappedComponent(props) {
    const hook = useAuth0();
    return <Component {...props} useAuth0={hook} />;
  };
};
