import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import { Auth0Provider } from './util/react-auth0-wrapper';
import Config from './Config';
import { BrowserRouter } from 'react-router-dom';
import Axios from 'axios';
import styled from 'styled-components';
import ReactLoader from 'react-loader-spinner';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Bounce, ToastContainer, toast } from 'react-toastify';
// import { Auth0Provider } from '@auth0/auth0-react';
import 'react-toastify/dist/ReactToastify.min.css';

const ErrorMessages = () => {
  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.data.type === 'payment') {
          toast(
            'We could not handle your request. Please check your payment settings.',
            {
              type: 'error',
            },
          );
        }
        if (error.response.data.statusCode === 'not_empty') {
          toast(
            'We could not process your request. Please make sure there are no active surveys in the resource you are trying to delete.',
            {
              type: 'error',
            },
          );
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return null;
};

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
`;

const Loader = (props) => {
  const [amountLoaders, setAmountLoaders] = useState(0);

  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => {
        setAmountLoaders((amountLoaders) => amountLoaders - 1);
        return response;
      },
      (error) => {
        setAmountLoaders((amountLoaders) => amountLoaders - 1);
        return Promise.reject(error);
      }
    );

    Axios.interceptors.request.use((request) => {
      setAmountLoaders((amountLoaders) => amountLoaders + 1);
      return request;
    });
  }, [setAmountLoaders]);

  if (amountLoaders > 0) {
    return (
      <LoaderWrapper>
        <div className="absolute w-100 h-100 top-1/2 right-1/2 translate-x-[50%]">
          <ReactLoader type="ThreeDots" color="#FFF" height={100} width={100} />
        </div>
      </LoaderWrapper>
    );
  }

  return <></>;
};

Axios.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // localStorage.removeItem('token');
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <>
    <BrowserRouter>
      <Auth0Provider
        domain={Config.auth.domain}
        client_id={Config.auth.clientId}
        redirect_uri={window.location.origin+'/app'}
        audience={Config.auth.audience}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
    ></ToastContainer>
    <ErrorMessages></ErrorMessages>
    <Loader></Loader>
  </>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
