import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import Table from '../components/common/Table';
import AlertMessage from '../components/common/AlertMessage';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Project = ({ currentUser }) => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);
  const [isAdmin] = useState(currentUser?.role?.name === 'super admin');

  const warningMessage =
    'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    name: 'Naam',
    organizations: 'Organisaties',
    users: 'Gebruikers',
  };

  const actionField = (item) => {
    return (
      <button onClick={() => onAddUser(item)}>
        <FontAwesomeIcon className="text-xl" icon={faUserPlus} />
      </button>
    );
  };

  const addProject = async (organizationId) => {
    if (!organizationId) return;

    await Axios.post(`${Config.apiUrl}/api/v1/projects`, {
      name: selectedProject?.name?.trim(),
      organization: organizationId,
    });

    await fetchData();
    toast('Project succesvol toegevoegd');
  };

  const updateProject = async (event) => {
    event.preventDefault();

    const organizationId = isAdmin
      ? selectedProject?.organizationId
      : currentUser?.organization?._id;

    try {
      if (!selectedProject?._id) {
        await addProject(organizationId);
      } else {
        await Axios.patch(
          `${Config.apiUrl}/api/v1/projects/${selectedProject?._id}`,
          {
            name: selectedProject?.name?.trim(),
            organization: organizationId,
          },
        );
        await fetchData();
        toast('Project updated successfully');
      }

      setSelectedProject(null);
      setShowUpdateModal(false);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    const organization = JSON.parse(localStorage.getItem('sa-organization'));

    let endpoint = '';

    if (isAdmin && organization?._id) {
      endpoint = `/api/v1/projects?page=${page}&organization=${organization?._id}`;
    } else endpoint = `/api/v1/projects?page=${page}`;

    try {
      const {
        data: { data, meta },
      } = await Axios.get(`${Config.apiUrl}${endpoint}`);

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setProjects(data);
    } catch (error) {
      toast('Failed to fetch projects. Please try again later.', {
        type: 'error',
      });
    }
  };

  const fetchUsers = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(`${Config.apiUrl}/api/v1/users/all`);

      setUsers(data);
    } catch (error) {
      toast('Failed to fetch users. Please try again later.', {
        type: 'error',
      });
    }
  };

  const fetchOrganization = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(`${Config.apiUrl}/api/v1/organizations`);
      if (currentUser?.role?.name === 'admin') {
        setOrganizations(
          data.filter((item) => item._id === currentUser?.organization?._id),
        );
      } else {
        setOrganizations(data);
      }
    } catch (error) {
      toast('Failed to fetch organizations. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onAddUser = (project) => {
    setShowAddUserModal(true);
    setSelectedProject(project);
  };

  const onUpdate = (project) => {
    setSelectedProject(project);
    setShowUpdateModal(true);
  };

  const onAdd = () => {
    const organization = JSON.parse(localStorage.getItem('sa-organization'));
    let organizationId = '';

    if (isAdmin && organization?._id) {
      organizationId = organization?._id;
    } else if (isAdmin && !organization?._id) {
      organizationId = '';
    } else {
      organizationId = currentUser?.organization?._id;
    }

    setShowUpdateModal(true);
    setSelectedProject({
      name: '',
      organizationId: organizationId,
    });
  };

  const onDelete = async (project) => {
    setSelectedProject(project);
    setShowDeleteModal(true);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedProject(null);
  };

  const deleteProject = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/projects/${selectedProject?._id}`,
      );

      setProjects((prevProjects) =>
        prevProjects.filter((o) => o._id !== selectedProject?._id),
      );

      setSelectedProject(null);
      setShowDeleteModal(false);
      await fetchData();
      toast('Project deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateProject = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setSelectedProject({
      ...selectedProject,
      [key]: value,
    });
  };

  const addUserToProject = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/users/${selectedUser}/assign-project`,
        {
          projectId: selectedProject?._id,
        },
      );

      setSelectedUser('');
      setSelectedProject(null);
      setShowAddUserModal(false);
      await fetchData();
      await fetchUsers();

      toast('User added successfully');
    } catch (err) {
      toast(err?.response?.data?.message || 'Failed to add user to project.', {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchData();
      fetchUsers();
      fetchOrganization();

      if (currentUser.role.name === 'admin' && !currentUser?.organization) {
        setShowOrganizationAlert(true);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="h-screen pt-32 px-4">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}

      <div
        className={
          showOrganizationAlert
            ? 'opacity-60 pointer-events-none select-none'
            : ''
        }
      >
        {currentUser?.role?.name !== 'project manager' && (
          <div className="flex justify-end">
            <button
              type="button"
              className="auth-button w-[200px] mb-4"
              onClick={onAdd}
            >
              Toevoegen
            </button>
          </div>
        )}
      </div>
      {showUpdateModal && (
        <Modal
          action={selectedProject?._id ? 'Update' : 'Create'}
          title="Project"
          close={() => closeUpdateModal()}
        >
          <form
            className="grid grid-cols-1 gap-4 w-full"
            onSubmit={updateProject}
          >
            {isAdmin && (
              <select
                className="select rounded min-w-full"
                value={selectedProject?.organizationId}
                name="organizationId"
                onChange={handleUpdateProject}
                required
              >
                <option value="">Selecteer een organisatie</option>
                {organizations.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}

            <input
              className="text-black rounded input bg-transparent"
              placeholder="Voer de naam van de project in"
              name="name"
              value={selectedProject?.name}
              onChange={handleUpdateProject}
              required
            />

            <div className="flex justify-between">
              <button
                type="button"
                className="auth-button w-[100px]"
                onClick={() => closeUpdateModal()}
              >
                Cancel
              </button>

              <button type="submit" className="auth-button w-[100px]">
                {selectedProject?._id ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Project"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteProject}
          type="delete"
          name={selectedProject?.name}
        />
      )}
      {showAddUserModal &&
        (isAdmin || currentUser?.role?.name === 'project manager') && (
          <Modal
            title="Gebruiker toevoegen"
            close={() => setShowAddUserModal(false)}
            showFullTitle
          >
            <form className="flex space-x-4" onSubmit={addUserToProject}>
              <select
                className="select rounded"
                value={selectedUser}
                onChange={(event) => setSelectedUser(event.target.value)}
                required
              >
                <option value="">Selecteer gebruiker</option>
                {users?.map((user) => (
                  <option key={user?._id} value={user?._id}>
                    {user?.name}
                  </option>
                ))}
              </select>
              <button type="submit" className="auth-button w-[200px]">
                Toevoegen
              </button>
            </form>
            <p>
              <br />
              <i>
                Staat de gebruiker er niet tussen?{' '}
                <a href="/app/admin/users">
                  <u>Maak eerst het gebruikersaccount aan</u>
                </a>
                .
              </i>
            </p>
          </Modal>
        )}
      <Table
        fields={fields}
        data={projects}
        actions={{ onUpdate, onDelete }}
        extraActionFields={
          isAdmin || currentUser?.role?.name === 'project manager'
            ? [actionField]
            : []
        }
        hasPagination={true}
        page={page}
        setPage={setPage}
        meta={meta}
        hideActions={currentUser?.role?.name === 'project manager'}
        ignoredFields={['organizationId']}
      />
    </div>
  );
};
export default Project;
