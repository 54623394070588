import { useEffect, useState } from 'react';
import Progress from '../components/survey/Progress';
import Question from '../components/survey/Question';
import Heart from '../img/heart.png';
import Time from '../img/timer.png';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import config from '../Config';
import axios from 'axios';
import FinalQuestion from '../components/survey/FinalQuestion';

const SurveyQuestion = ({ currentUser }) => {
  const [topics, setTopics] = useState([]);
  const [topicsData, setTopicsData] = useState([]);
  const [selectedSubTopics, setSelectedSubTopics] = useState([]);
  const [mainTopics, setMainTopics] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [savedSurvey, setSavedSurvey] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const params = useParams();
  let history = useHistory();

  const maxSelectedSubTopics = 3;

  const next = () => {
    trackProgress();
    setCurrentIndex(
      currentIndex === savedSurvey.answers.length
        ? savedSurvey.answers.length
        : currentIndex + 1,
    );
  };

  const previous = () => {
    if (currentIndex < 1) return;
    setCurrentIndex(currentIndex - 1);
  };

  const isNumber = (value) => {
    return !isNaN(value) && value.trim() !== '';
  };

  const onChange = (value) => {
    const updateSavedSurvey = { ...savedSurvey };
    updateSavedSurvey.answers[currentIndex].answer = isNumber(value)
      ? +value
      : value;

    setSavedSurvey(updateSavedSurvey);
  };

  const isChoiceSelected = (question) => {
    if (
      question?.type === 'multiple' &&
      question?.answer !== 0 &&
      typeof question?.answer !== 'string'
    )
      return false;

    return true;
  };

  const onSubTopicOptionsChange = (event) => {
    const value = event.target.value;

    if (!value) return;

    if (selectedSubTopics.includes(value)) {
      const filteredSubTopics = [...selectedSubTopics].filter(
        (topic) => value !== topic,
      );
      setSelectedSubTopics(filteredSubTopics);
    } else {
      if (selectedSubTopics.length === maxSelectedSubTopics) {
        toast('You have selected maximum sub topics', {
          type: 'info',
        });

        return;
      }
      setSelectedSubTopics([...selectedSubTopics, value]);
    }
  };

  const onSave = async () => {
    trackProgress();
    try {
      const {
        data: { data },
      } = await axios.post(`${config.apiUrl}/api/v1/survey-histories`, {
        answer: savedSurvey,
        favorite_topics: selectedSubTopics,
      });
      history.push(`/app/surveys/${data._id}/preview`);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const trackProgress = () => {
    const updateSavedSurvey = { ...savedSurvey };
    if (updateSavedSurvey.answers[currentIndex])
      updateSavedSurvey.answers[currentIndex].isTaken = true;
    setSavedSurvey(updateSavedSurvey);
    localStorage.setItem('survey', JSON.stringify(updateSavedSurvey));
  };

  const arrangeQuestions = (questionsArray) => {
    if (
      !Array.isArray(questionsArray) ||
      (Array.isArray(questionsArray) && !questionsArray?.length)
    )
      return [];

    const topicsMap = new Map();

    // Separate main topics and subtopics
    questionsArray.forEach((question) => {
      const { topic } = question;

      if (!topic.parent) {
        if (!topicsMap.has(topic._id)) {
          topicsMap.set(topic._id, { mainTopic: [], subTopics: [] });
        }
        topicsMap.get(topic._id).mainTopic.push(question);
      } else {
        if (!topicsMap.has(topic.parent)) {
          topicsMap.set(topic.parent, { mainTopic: [], subTopics: [] });
        }
        topicsMap.get(topic.parent).subTopics.push(question);
      }
    });

    // flatten the map into an array with main topics followed by their subtopics
    const arrangedQuestions = [];
    topicsMap.forEach(({ mainTopic, subTopics }) => {
      arrangedQuestions.push(...mainTopic);
      arrangedQuestions.push(...subTopics);
    });

    return arrangedQuestions;
  };

  const updateMainTopicsWithSubtopics = (topicsArray = [], topicsData = []) => {
    if (!Array.isArray(topicsArray) || !Array.isArray(topicsData)) return [];

    if (!topicsArray?.length || !topicsData?.length) return [];

    const updatedTopicsArray = [...topicsData].map((topic) => {
      const topicQuestions = topicsArray.filter(
        (item) =>
          item.id === topic._id ||
          (item.isSubTopic && item.parent === topic._id),
      );

      let max = 0;
      let value = 0;

      topicQuestions.forEach((question) => {
        max += question?.max;
        value += question?.value;
      });

      return {
        value,
        max,
        text: topic?.title,
        isSubTopic: false,
      };
    });
    return updatedTopicsArray;
  };

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('survey'));

    if (saved != null && saved?.id === +params.id) {
      setSavedSurvey(saved);
      const previousIndex = savedSurvey?.answers?.findIndex(
        (item) => item.isTaken,
      );
      if (previousIndex) {
        setCurrentIndex(previousIndex + 1);
      }
      return;
    }

    async function fetchData() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${config.apiUrl}/api/v1/survey-questions?category=${params.category}&all=1`,
        );

        const topicResults = await axios.get(
          `${config.apiUrl}/api/v1/survey-topics/all?grouped=1`,
        );

        if (topicResults?.data?.data?.length)
          setTopicsData(topicResults?.data?.data);

        if (data?.length === 0) {
          setSavedSurvey(null);
          return;
        }

        const arrangedData = arrangeQuestions(data);

        const questions = {
          _id: data.at(0)?._id,
          title: data.at(0)?.title,
          parent: data.at(0)?.parent,
          type: params.category,
          answers: arrangedData.map((item) => ({
            ...item,
            answer: 1,
            isTaken: false,
          })),
        };

        setSavedSurvey(questions);
      } catch (error) {
        toast('Failed to fetch questions. Please try again later.', {
          type: 'error',
        });
      } finally {
        setIsloading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (savedSurvey) {
      const topics = Array.from(
        new Map(
          savedSurvey.answers.map((question) => [
            question?.topic?.title,
            {
              title: question?.topic?.title,
              parent: question?.topic?.parent,
              id: question?.topic?._id,
            },
          ]),
        ).values(),
      ).map((topic) => {
        const newItem = {
          id: topic?.id,
          text: topic?.title,
          value: 0,
          max: 0,
          isSubTopic: topic?.parent !== null,
          parent: topic?.parent,
        };
        savedSurvey.answers.forEach((item) => {
          if (item?.topic?.title === topic?.title) {
            newItem.max++;
          }
          if (item?.topic?.title === topic?.title && item.isTaken) {
            newItem.value++;
          }
        });

        return newItem;
      });
      setTopics(topics);
    }
  }, [savedSurvey]);

  useEffect(() => {
    const main = updateMainTopicsWithSubtopics(topics, topicsData)?.filter(
      (item) => item?.max,
    );

    setMainTopics(main);
  }, [topics, topicsData]);

  return (
    <>
      {savedSurvey != null ? (
        <div className="relative flex flex-col justify-center min-h-screen max-w-[1600px] mx-auto">
          <div className="flex items-center justify-center">
            <img
              src={params.category === 'quick' ? Time : Heart}
              alt="question type"
              className="hidden md:block w-40 h-40 -right-14 md:-right-4 lg:-right-0 -top-32 md:-top-14"
            />
          </div>
          <div
            className="flex flex-col space-y-14 p-20"
            style={{
              backgroundColor: 'white',
              border: '2px solid rgb(0, 107, 0)',
              borderRadius: '40px',
            }}
          >
            {savedSurvey?.answers?.length === currentIndex ? (
              <FinalQuestion
                topicsData={topicsData}
                selectedSubTopics={selectedSubTopics}
                onChange={onSubTopicOptionsChange}
              />
            ) : (
              <Question
                {...savedSurvey.answers[currentIndex]}
                index={currentIndex}
                onChange={onChange}
              />
            )}

            <div className="flex justify-between w-full">
              <button
                className="btn btn-outline hover:bg-black text-black hover:text-white btn-rounded"
                onClick={previous}
                disabled={!currentIndex}
              >
                Vorige
              </button>
              {savedSurvey?.answers?.length === currentIndex ? (
                <button
                  className="button hover:bg-black"
                  onClick={onSave}
                  disabled={selectedSubTopics.length !== maxSelectedSubTopics}
                >
                  Save
                </button>
              ) : (
                <button
                  className="button hover:bg-black"
                  onClick={next}
                  disabled={
                    !isChoiceSelected(savedSurvey?.answers[currentIndex])
                  }
                >
                  Volgende
                </button>
              )}
            </div>
          </div>

          <div className="w-full flex px-20 mt-10">
            <div className="md:w-[90%] w-full grid grid-cols-2 lg:grid-cols-6 md:px-3 gap-y-4 gap-x-4 items-center">
              {mainTopics.map((item, index) => (
                <Progress
                  title={item.text}
                  max={item.max}
                  value={item.value}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          {!isLoading && !savedSurvey && (
            <div className="h-screen pt-32 px-4">
              <h6>Er is geen vraag gevonden voor deze categorie</h6>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SurveyQuestion;
