import { useEffect, useState } from 'react';
import CircularProgress from '../components/survey/CircularProgress';
import Heart from '../img/heart.png';
import Time from '../img/timer.png';
import { useHistory, useParams, Link } from 'react-router-dom';
import Axios from 'axios';
import Config from '../Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Question from '../components/survey/Question';
import Modal from '../components/common/Modal';
import { toast } from 'react-toastify';
import Chart from 'react-apexcharts';
import { chartOptions } from '../util/util';
import { jsPDF } from 'jspdf';
import { toPng } from 'html-to-image';
import Accordion from '../components/common/Accordion';

const SurveyPreview = ({ currentUser }) => {
  const [survey, setSurvey] = useState({});
  const [surveyHistory, setSurveyHistory] = useState(null);
  const [surveyAns, setSurveyAns] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [resultOf, setResultOf] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [selectedAnsIndex, setSelectedAnsIndex] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [topicScores, setTopicScores] = useState();
  const [favoriteTopics, setFavoriteTopics] = useState();
  const [series, setSeries] = useState([]);
  const [xaxis, setXaxis] = useState({});
  let history = useHistory();
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);

  const exportPdf = () => {
    document.querySelectorAll('input.range-slider').forEach((input) => {
      input.classList.add('export');

      let thumbnWidth = 14;
      let offset = input.clientWidth / 10;
      let left =
        (input.valueAsNumber - 0) * offset -
        (input.valueAsNumber / 10 - 0 - 0.5) * thumbnWidth;

      input.style.setProperty('--left', `${left - 6}px`);
    });

    const eleemnt = document.getElementById('survey');
    const htmlWidth = eleemnt.getClientRects()[0]?.width;
    const htmlHeight = eleemnt.getClientRects()[0]?.height;
    const topLeftMargin = 100;

    let pdfWidth = htmlWidth + topLeftMargin * 2;
    let pdfHeight = pdfWidth * 1.2 + topLeftMargin * 2;

    const canvasImageWidth = htmlWidth;
    const canvasImageHeight = htmlHeight;

    const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

    toPng(eleemnt, { skipFonts: true })
      .then((dataUrl) => {
        let pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight], true);
        pdf.addImage(
          dataUrl,
          'png',
          topLeftMargin,
          -50,
          canvasImageWidth,
          canvasImageHeight,
        );

        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage([pdfWidth, pdfHeight], 'p');
          pdf.addImage(
            dataUrl,
            'png',
            topLeftMargin,
            -(pdfHeight * i + 20),
            canvasImageWidth,
            canvasImageHeight,
          );
        }

        pdf.save(
          `Resultaten - ${new Date(
            createdAt,
          ).toLocaleDateString()} - ${new Date(
            createdAt,
          ).toLocaleTimeString()}`,
        );
      })
      .finally(() => {
        document.querySelectorAll('input.range-slider').forEach((input) => {
          input.classList.toggle('export');
        });
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { data },
        } = await Axios.get(
          `${Config.apiUrl}/api/v1/survey-histories/${params.id}`,
        );

        if (data !== null) {
          setResultOf(data.user);
          setSurveyAns(data.answer);
          setSurvey(data.survey);
          setCreatedAt(data.createdAt);
          setFavoriteTopics(data?.favorite_topics ?? []);
        }
      } catch (error) {
        toast('Failed to fetch survey history. Please try again later.', {
          type: 'error',
        });
      } finally {
        setIsloading(false);
      }
    }

    async function fetchSurveyHistoryData() {
      try {
        const {
          data: { data },
        } = await Axios.get(
          `${Config.apiUrl}/api/v1/survey-histories${
            searchParams.get('user_id')
              ? '?user_id=' + searchParams.get('user_id')
              : ''
          }`,
        );
        setSurveyHistory(data?.slice(0, 4));
      } catch (error) {
        toast('Failed to fetch survey histories. Please try again later.', {
          type: 'error',
        });
      } finally {
        setIsloading(false);
      }
    }

    fetchData();
    fetchSurveyHistoryData();
    // eslint-disable-next-line
  }, [params]);

  const onUpdate = (answer) => {
    const realIndex = surveyAns?.answers?.findIndex(
      (ans) => ans?._id === answer?._id,
    );

    setSelectedAnswer(answer);
    setSelectedAnsIndex(realIndex);
    setShowUpdateModal(true);
  };

  const isNumber = (value) => {
    return !isNaN(value) && value.trim() !== '';
  };

  const onChange = (value) => {
    setSelectedAnswer({
      ...selectedAnswer,
      answer: isNumber(value) ? +value : value,
    });
  };

  const updateAnswer = async (event, ans, msg, type = 'update') => {
    try {
      const updateSavedSurvey = { ...surveyAns };
      updateSavedSurvey.answers[selectedAnsIndex] = ans ? ans : selectedAnswer;

      const isAddingAns =
        updateSavedSurvey.answers[selectedAnsIndex].answer > 0 &&
        !updateSavedSurvey.answers[selectedAnsIndex].isTaken;

      if (isAddingAns) {
        updateSavedSurvey.answers[selectedAnsIndex].isTaken = true;
        type = 'add';
      }

      await Axios.patch(
        `${Config.apiUrl}/api/v1/survey-histories/${params.id}`,
        {
          survey: survey?._id,
          answer: updateSavedSurvey,
          favorite_topics: favoriteTopics,
        },
      );

      toast(
        msg
          ? msg
          : isAddingAns
          ? 'Answer added successfully'
          : 'Answer updated successfully',
      );
      setSurveyAns(() => updateSavedSurvey);
      setShowUpdateModal(false);
      setSelectedAnswer({});
      setSelectedAnsIndex(0);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const getAnswePercentage = (questions, mark) => {
    return Math.round((mark / questions) * 10);
  };

  const genChartData = (history) => {
    const chartData = [];
    const data = history?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    let currentResultIndex = data.findIndex((item) => item?._id === params?.id);
    let currentResult = { answer: surveyAns };
    let previousResult = data[currentResultIndex + 1];

    const currentTopicData = calculateTopicScores(
      currentResult?.answer?.answers,
    );
    const previousTopicData = calculateTopicScores(
      previousResult?.answer?.answers,
    );

    if (currentResult) {
      chartData.push({
        name: 'Huidig',
        data: Object.values(currentTopicData || {}),
      });
    }

    if (previousResult) {
      chartData.push({
        name: 'Vorige',
        data: Object.values(previousTopicData || {}),
      });
    } else {
      chartData.push({
        name: 'Vorige',
        data: new Array(chartData[0]?.data?.length).fill(0),
      });
    }

    return chartData;
  };

  useEffect(() => {
    if (surveyAns) {
      const topics = calculateTopicScores(surveyAns.answers);

      setTopicScores(topics);
    }

    // eslint-disable-next-line
  }, [surveyAns]);

  useEffect(() => {
    if (surveyHistory?.length > 0) {
      const data = genChartData([...surveyHistory]);

      setXaxis(() => ({
        categories: Object.keys(calculateTopicScores(surveyAns?.answers) || {}),
      }));

      setSeries(() => data);
    }
    // eslint-disable-next-line
  }, [surveyHistory, surveyAns]);

  const groupAnswersByTopic = (answers = []) => {
    const groups = {};

    answers.forEach((answer) => {
      const topic = answer?.topic?.title;

      if (groups[topic]) {
        groups[topic].push(answer);
      } else {
        groups[topic] = [answer];
      }
    });

    return groups;
  };

  const calculateTopicScores = (answers = []) => {
    const formattedAnswers = answers.map((answer) => {
      if (answer?.type === 'multiple') {
        if (typeof answer?.answer === 'string' && answer?.answer.length > 0) {
          return { ...answer, answer: 10 };
        } else if (
          typeof answer?.answer === 'string' &&
          answer?.answer.length === 0
        ) {
          return { ...answer, answer: 1 };
        } else {
          return answer;
        }
      }

      return answer;
    });
    const scores = {};

    const topicsData = Array.from(
      new Set(answers?.map((question) => question.topic?.title)),
    ).map((topic) => {
      const newItem = { text: topic, value: 0, max: 0, total_answer: 0 };

      formattedAnswers.forEach((item) => {
        if (item.topic?.title === topic) {
          newItem.max++;
          newItem.total_answer += item.answer;
        }

        if (item.topic?.name === topic && item.isTaken) {
          newItem.value++;
        }
      });
      return newItem;
    });

    topicsData.forEach((topic) => {
      const percentage = getAnswePercentage(topic?.max, topic?.total_answer);

      scores[topic?.text] = percentage;
    });

    return scores;
  };

  const getYesNoAnswers = () => {
    return surveyAns?.answers?.filter((answer) => answer?.type === 'yes/no');
  };

  const getAnswerAdvice = (answer) => {
    const adviceMap = {
      1: 'no',
      10: 'yes',
    };

    return answer.property.advices[adviceMap[answer.answer]];
    // return answer?.property?.advices?.[adviceMap[answer?.answer]];
  };

  const getAnswerValuePercentage = (answer = null) => {
    if (!answer) return 0;

    let value = answer.answer;

    if (answer?.type === 'multiple') {
      if (typeof answer?.answer === 'string' && answer?.answer.length > 0) {
        value = 10;
      } else if (
        typeof answer?.answer === 'string' &&
        answer?.answer.length === 0
      ) {
        value = 1;
      } else {
        value = answer.answer;
      }
    }

    return value * 10;
  };

  if (surveyAns == null && !isLoading)
    return (
      <div className="w-full h-screen text-black pt-32 space-y-20 md:space-y-16">
        <h2 className="text-black">Enquête Resultaat is niet gevonden</h2>
      </div>
    );
  return (
    <>
      {!isLoading && surveyAns && (
        <div className="max-w-[1600px] mx-auto">
          <div
            className="w-full text-black pt-32 pb-24 space-y-20 md:space-y-16"
            id="survey"
          >
            <div className="text-left md:space-y-5">
              <h3 className="font-bold mb-4 text-2xl">
                {surveyAns?.type === 'quick' ? 'Snel' : 'Uitgebreid'} -
                Resultaten - {new Date(createdAt).toLocaleDateString()}
              </h3>
              <p className="mb-4">
                Deze rapportage geeft je een persoonlijk overzicht van jouw
                veerkracht op dit moment, gebaseerd op jouw antwoorden in de
                Veerkrachtscan. Het biedt inzichten in de gebieden waar je sterk
                in staat en waar je wensen hebt voor verbeteringen.
              </p>
            </div>
            <div className="flex flex-col-reverse lg:flex-row gap-16 lg:gap-0 lg:space-x-8">
              <div className="flex-[1] md:flex-[3] lg:flex-[5] flex flex-wrap border-2 border-black rounded-2xl justify-center gap-10 p-10">
                {Object.keys(topicScores)?.map((topic, index) => {
                  const cleanedTopic = topic
                    .replace(/\s*\(.*?\)\s*/g, '')
                    .trim();
                  return (
                    <div
                      key={`topic-${index}`}
                      title={cleanedTopic}
                      className="flex flex-col gap-2 items-center max-w-[150px]"
                    >
                      <div className="h-28 w-28">
                        <CircularProgress
                          value={topicScores[topic]}
                          showPercentageValue={false}
                        />
                      </div>
                      <p className="capitalize text-center font-bold leading-tight">
                        {cleanedTopic}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="flex-1 border-2 border-black rounded-2xl px-10 py-5">
                <h5 className="text-black font-medium md:text-xl mb-2">
                  Geschiedenis
                </h5>
                <div>
                  {surveyHistory?.map((item, index) => (
                    <Link
                      to={`/app/surveys/${item?._id}/preview`}
                      key={item?._id}
                    >
                      <h5
                        className="whitespace-nowrap text-black font-medium md:text-xl"
                        key={item?._id}
                      >
                        {index + 1}.{' '}
                        {new Date(item.createdAt).toLocaleDateString()} -{' '}
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </h5>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="border-2 border-black rounded-2xl px-10 py-5">
              <Chart
                options={{ ...chartOptions, xaxis }}
                series={series}
                type="bar"
                width="100%"
                height={250}
                className="pointer-events-none"
              />
            </div>

            {getYesNoAnswers()?.length > 0 && (
              <div className="border-2 border-black rounded-2xl px-10 py-5">
                <h5 className="text-black font-medium md:text-xl mb-2">
                  Adviezen
                </h5>
                <div>
                  {getYesNoAnswers()?.map((answer) => {
                    const advice = getAnswerAdvice(answer);
                    if (advice) {
                      return (
                        <li
                          key={answer?._id}
                          className="whitespace-nowrap text-black font-medium md:text-xl"
                        >
                          {answer.title} -{' '}
                          {answer.answer === 1
                            ? 'Nee'
                            : answer.answer === 10
                            ? 'Ja'
                            : 'N.v.t'}
                          <br />
                          <i>{advice}</i>
                        </li>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}

            <div className="border-2 border-black rounded-2xl  p-10 flex flex-col space-y-10">
              <div className="flex justify-center">
                <img
                  src={surveyAns?.type === 'quick' ? Time : Heart}
                  alt="question type"
                  className="w-40 h-40"
                />
              </div>

              {showUpdateModal && (
                <Modal
                  close={() => setShowUpdateModal(false)}
                  action={'Update'}
                  title="Antwoord"
                  large={true}
                >
                  <div className="flex flex-col space-y-10 p-8">
                    <Question
                      {...selectedAnswer}
                      index={selectedAnsIndex}
                      onChange={onChange}
                    />

                    <div className="flex justify-between w-full">
                      <button
                        className="btn btn-outline hover:bg-black text-black hover:text-white btn-rounded"
                        onClick={() => setShowUpdateModal(false)}
                      >
                        Annuleer
                      </button>
                      <button
                        className="button hover:bg-black"
                        onClick={updateAnswer}
                      >
                        Opslaan
                      </button>
                    </div>
                  </div>
                </Modal>
              )}

              {surveyAns?.answers &&
                Object.keys(groupAnswersByTopic(surveyAns?.answers))?.map(
                  (group) => (
                    <Accordion
                      title={group}
                      score={topicScores[group]}
                      defaultOpen={false}
                      key={group}
                    >
                      {groupAnswersByTopic(surveyAns?.answers)?.[group]?.map(
                        (item, index) => (
                          <div
                            className="w-full question-answer !mt-10"
                            key={`preview-${index}`}
                          >
                            <h5 className="text-black font-medium text-sm md:text-xl md:mb-0 sm:mb-10">
                              {index + 1}. {item?.title}{' '}
                              {item?.type === 'range' && (
                                <>
                                  {item?.property?.startText} -{' '}
                                  {item?.property?.endText}{' '}
                                </>
                              )}
                              {!item.isTaken && (
                                <small>
                                  <i>(niet geantwoord)</i>
                                </small>
                              )}
                            </h5>
                            <div className="flex items-center space-x-5 w-full group">
                              <div className="relative w-full">
                                {currentUser?._id === resultOf && (
                                  <div className="absolute mr-4 right-0 top-[-30px] opacity-0 group-hover:opacity-100 transition">
                                    <button
                                      className="mr-4"
                                      onClick={() => onUpdate(item)}
                                    >
                                      <FontAwesomeIcon
                                        className="text-xl"
                                        icon={faPen}
                                      />
                                    </button>
                                  </div>
                                )}
                                {item?.type === 'range' && (
                                  <input
                                    type="range"
                                    step={1}
                                    className={`flex-1 bg-transparent range-slider`}
                                    disabled
                                    min={1}
                                    max={10}
                                    value={item.answer}
                                  />
                                )}

                                {item?.type === 'yes/no' && (
                                  <div className="pt-1 w-full">
                                    <div
                                      id="group1"
                                      className="flex flex-row gap-3 justify-center"
                                    >
                                      <label className="flex cursor-pointer gap-2">
                                        <input
                                          type="radio"
                                          className="radio radio-success"
                                          name="group3"
                                          checked={item.answer === 10}
                                          readOnly
                                        />
                                        <span>Ja</span>
                                      </label>
                                      <label className="flex cursor-pointer gap-2">
                                        <input
                                          type="radio"
                                          className="radio radio-error"
                                          name="group3"
                                          checked={item.answer === 1}
                                          readOnly
                                        />
                                        <span>Nee</span>
                                      </label>
                                    </div>
                                  </div>
                                )}

                                {item?.type === 'multiple' && (
                                  <ul className="flex flex-wrap gap-6 pl-[2px] max-h-44 overflow-y-auto">
                                    {item?.property?.choices.map((choice) => (
                                      <li
                                        className="mb-2 flex items-center gap-2"
                                        key={choice}
                                      >
                                        <input
                                          id={choice}
                                          type="radio"
                                          className="radio radio-secondary"
                                          value={choice}
                                          onChange={() => null}
                                          checked={item?.answer === choice}
                                        />
                                        <label
                                          htmlFor={choice}
                                          className="cursor-pointer capitalize"
                                        >
                                          {choice}
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              <div className="h-20 w-20">
                                <CircularProgress
                                  value={getAnswerValuePercentage(item)}
                                  showPercentageValue={false}
                                />
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </Accordion>
                  ),
                )}
            </div>
          </div>
          <div>
            {surveyAns.answers && (
              <div className="flex justify-between w-full pb-20">
                <button
                  className="btn w-max bg-black text-white"
                  onClick={() => history.push('/app')}
                >
                  Opnieuw beginnen
                </button>
                <button className="btn btn-warning w-max" onClick={exportPdf}>
                  Export naar PDF
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default SurveyPreview;
