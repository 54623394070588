import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from "../../util/react-auth0-wrapper";

const PrivateRoute = ({ component: Component, path, roles = [], ...rest }) => {
  const { loading, appUser } = useAuth0();

  const render = props => <Component {...props} />;
  if(loading === true) {
    return <div className="h-screen text-black">Loading...</div>;;
  }

  const hasPermission = (userRole) => {
    if (!roles || roles.length === 0) return true;
    return roles.some((role) => role === userRole);
  };

  if(!appUser){
    return <Redirect to="/login" />;
  }

  if (appUser && !hasPermission(appUser?.role?.name)) {
    return <Redirect to="/app" />;
  }

  // if(!appUser.initialized){
  //   // User is not initialized
  //   return <InitializeUser></InitializeUser>;
  // }
 
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;