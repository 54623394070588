import Axios from "axios";
import { useEffect, useState } from "react";
import Config from "../Config";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "../util/react-auth0-wrapper";
import Modal from "../components/common/Modal";
import Table from "../components/common/Table";
import AlertMessage from "../components/common/AlertMessage";

const User = ({ currentUser }) => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const { appUser } = useAuth0();
  const [isAdmin] = useState(currentUser?.role?.name === 'admin' || false);
  const [isProjectManager] = useState(currentUser?.role?.name === 'project manager')
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    organizationId: isAdmin ? currentUser?.organization?._id : '',
    roleId: '',
  });

  const warningMessage =
    'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    name: 'Naam',
    email: 'Email',
    role: 'Rol',
    organization: 'Organisatie',
    project: 'Project',
  };

  const transformData = (data) => {
    return data?.map((item) => ({
      _id: item?._id,
      name: item?.name,
      email: item?.email,
      role: item.role?.name ?? 'N/A',
      organization: item.organization?.name ?? 'N/A',
      project: item.project?.name ?? 'N/A',
    }));
  };

  const actionField = (item) => {
    return (
      <NavLink
        key={item._id}
        className="text-black cursor-pointer"
        to={`/app/history/${item.auth0_id}`}
      >
        Bekijk historie
      </NavLink>
    );
  };

  const addUser = async (event) => {
    event.preventDefault();

    if (showOrganizationAlert) return;
    if (!validate(newUser)) return;

    let user = {...newUser}

    if(isProjectManager){
      user.organizationId = appUser.organization?._id;
    }

      try {
      await Axios.post(`${Config.apiUrl}/api/v1/users`, {...user, name: user.name?.trim()});
      setNewUser({
        email: '',
        name: '',
        organizationId: '',
        roleId: '',
      })
     await fetchData()
      toast("User added successfully")
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  }

 const fetchData = async () => {
    try {
        const { data: { data, meta } } = await Axios.get(`${Config.apiUrl}/api/v1/users?page=${page}`);

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setUsers(data);
    } catch (error) {
      toast('Failed to fetch users. Please try again later.', {
        type: 'error',
      });
    }
  };

  const fetchRole = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(`${Config.apiUrl}/api/v1/roles`);
      if (appUser?.role?.name === 'admin') {
        setRoles(
          data?.filter((item) => !['admin', 'super admin'].includes(item.name)),
        );
      } else if (appUser?.role?.name === 'project manager') {
        setRoles(
          data?.filter(
            (item) =>
              !['admin', 'super admin', 'project manager'].includes(item.name),
          ),
        );
      } else setRoles(data);
    } catch (error) {
      toast('Failed to fetch roles. Please try again later.', {
        type: 'error',
      });
    }
}
const fetchOrganization = async () => {
    try {
        const { data: { data } } = await Axios.get(`${Config.apiUrl}/api/v1/organizations`);
        if(appUser?.role?.name === 'admin'){
           setOrganizations(data.filter(item => item._id === appUser?.organization?._id));
        } else {
          setOrganizations(data);
        }
    } catch (error) {
      toast('Failed to fetch organizations. Please try again later.', {
        type: 'error',
      });
    }
}

const fetchProject = async () => {
  try {
      const { data: { data } } = await Axios.get(`${Config.apiUrl}/api/v1/projects`);
      setProjects(data);
  } catch (error) {
    toast('Failed to fetch projects. Please try again later.', {
      type: 'error',
    });
  }
}

const onUpdate = (user) => {
  if (showOrganizationAlert) return;
  setSelectedUser({
    _id: user?._id,
    name: user?.name,
    email: user?.email,
    roleId: user?.roleId || user?.role.id,
    organizationId: user?.organizationId || user?.organization?._id,
    projectId: user?.projectId || user?.project?._id,
  });
  setShowUpdateModal(true);
};

const onDelete = async (user) => {
  if (showOrganizationAlert) return;
  setSelectedUser(user);
  setShowDeleteModal(true);
};

const deleteUser = async () => {
  try {
    await Axios.delete(`${Config.apiUrl}/api/v1/users/${selectedUser?._id}`);

    setUsers((prevUsers) =>
    prevUsers.filter((o) => o._id !== selectedUser?._id),
    );
    setSelectedUser(null);
    setShowDeleteModal(false);

    await fetchData()
    toast("User deleted successfully");
  } catch (error) {
    toast(error?.response?.data?.message, {
      type: 'error',
    });
  }
};

const updateUser = async (event) => {
  event.preventDefault();

  if (showOrganizationAlert) return;
  if (!validate(selectedUser)) return;

  try {
    await Axios.patch(`${Config.apiUrl}/api/v1/users/${selectedUser?._id}`,selectedUser);

    setUsers((prev) => {
      let index = prev.findIndex((o) => o._id === selectedUser?._id);
      prev[index] = {
        ...selectedUser,
        role: roles.find((o) => o.id === selectedUser?.roleId),
        organization: organizations.find((o) => o._id === selectedUser?.organizationId),
      };

      setSelectedUser(null);
      setShowUpdateModal(false);

      return prev;
    });

     await fetchData();

    toast("User updatetd successfully");
  } catch (error) {
    toast(error?.response?.data?.message, {
      type: 'error',
    });
  }
};

const validate = (user) => {
  const { email, roleId } = user;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let message = '';

  if (!email || !emailPattern.test(email)) {
    message = 'Please enter a valid email';
  }

  if (!roleId) {
    message = 'Please select a role';
  }

  if (message) {
    toast(message, {
      type: 'error',
    });
  }

  return message.length === 0;
};

  const handleRoleChange = (event, setUser) => {
    let roleId = event.target.value;

    if (roleId === roles?.find((role) => role?.name === 'super admin')?.id) {
      setUser((user) => ({
        ...user,
        roleId,
        organizationId: '',
        projectId: '',
      }));
    } else if (
      roleId !== roles?.find((role) => role?.name === 'user')?.id ||
      roleId !== roles?.find((role) => role?.name === 'project manager')?.id
    ) {
      setUser((user) => ({ ...user, roleId, projectId: '' }));
    } else {
      setUser((user) => ({ ...user, roleId }));
    }
  };

  useEffect(() => {
    fetchOrganization();
    fetchRole();
    fetchProject();
    // eslint-disable-next-line
  }, []);

useEffect( () => {
  fetchData();
  if (currentUser) {
    if (currentUser.role.name === 'admin' && !currentUser?.organization) {
      setShowOrganizationAlert(true);
    }
  }
  // eslint-disable-next-line
}, [page]);

  return (
      <div className="h-screen pt-32 px-4">
         {showOrganizationAlert && <AlertMessage message={warningMessage} />}
         <form className={`grid grid-cols-2 gap-4 w-full md:w-[600px] ${showOrganizationAlert && "opacity-60 pointer-events-none select-none"}`} onSubmit={addUser}>
          <input
            className="w-full text-black rounded input bg-transparent"
            placeholder="Voer de naam van de gebruiker in"
            required
            value={newUser.name}
            onChange={(event) => setNewUser({...newUser, name: event.target.value})}
          />
          <input
            className="w-full text-black rounded input bg-transparent"
            placeholder="Voer het e-mailadres van de gebruiker in"
            type="email"
            required
            value={newUser.email}
            onChange={(event) => setNewUser({...newUser, email: event.target.value})}
          />
          <select className="select rounded" value={newUser?.roleId} onChange={(event) => handleRoleChange(event, setNewUser)} required>
            <option value="">Selecteer rol</option>
            {roles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>))}
          </select>
          {!isAdmin && !isProjectManager && <select className="select rounded" value={newUser?.organizationId} onChange={(event) => setNewUser({...newUser, organizationId: event.target.value})} required
            disabled={newUser?.roleId === roles?.find((role) => role?.name === 'super admin')?.id} >
            <option value="">Selecteer een organisatie</option>
            {organizations.map((item, index) => (<option key={index} value={item._id}>{item.name}</option>))}
          </select>}

        <select
          className="select rounded"
          value={newUser?.projectId}
          onChange={(event) =>
            setNewUser({ ...newUser, projectId: event.target.value })
          }
          required
          disabled={
            newUser?.roleId !==
              roles?.find((role) => role?.name === 'user')?.id &&
            newUser?.roleId !==
              roles?.find((role) => role?.name === 'project manager')?.id
          }
        >
          <option value="">Selecteer een project</option>
          {projects.map((item, index) => (
            <option key={index} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
        <button type="submit" className="auth-button mb-4">
          Toevoegen
        </button>
      </form>
      {showUpdateModal && (
        <Modal title="Gebruiker" close={() => setShowUpdateModal(false)}>
          <form className="grid grid-cols-2 gap-4" onSubmit={updateUser}>
            <input
              className="w-full text-black rounded input bg-transparent"
              placeholder="Voer de naam van de gebruiker in"
              required
              value={selectedUser.name}
              onChange={(event) => setSelectedUser({...selectedUser, name: event.target.value})}
            />
            <input
              className="w-full text-black rounded input bg-transparent"
              placeholder="Voer het e-mailadres van de gebruiker in"
              required
              value={selectedUser.email}
              onChange={(event) => setSelectedUser({...selectedUser, email: event.target.value})}
            />
            <select className="select rounded" value={selectedUser?.roleId} onChange={(event) => handleRoleChange(event, setSelectedUser)} required>
              <option value="">Selecteer rol</option>
              {roles.map((item, index) => (<option key={index} value={item.id}	>{item.name}</option>))}
            </select>
            {!isAdmin && !isProjectManager && <select className="select rounded" value={selectedUser?.organizationId} onChange={(event) => setSelectedUser({...selectedUser, organizationId: event.target.value})}
              disabled={selectedUser?.roleId === roles?.find((role) => role?.name === 'super admin')?.id} required
              >
                <option value="">Selecteer een organisatie</option>
                {organizations.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            }
            <select
              className="select rounded"
              value={selectedUser?.projectId}
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  projectId: event.target.value,
                })
              }
              disabled={
                selectedUser?.roleId !==
                  roles?.find((role) => role?.name === 'user')?.id &&
                selectedUser?.roleId !==
                  roles?.find((role) => role?.name === 'project manager')?.id
              }
              required
            >
              <option value="">Selecteer een project</option>
              {projects.map((item, index) => (<option key={index} value={item._id}  >{item.name}</option>))}
            </select>
            <button type="submit" className="auth-button">Update</button>
          </form>
          </Modal>
        )}
        {showDeleteModal && (
          <Modal
            title="Gebruiker"
            action="Verwijderen"
            close={() => setShowDeleteModal(false)}
            confirm={deleteUser}
            type="delete"
            name={selectedUser?.name}
          />
        )}
        <Table
          fields={fields}
          data={users}
          transform={true}
          transformData={transformData}
          actions={{ onUpdate, onDelete }}
          extraActionFields={[actionField]}
          hasPagination={true}
          page={page}
          setPage={setPage}
          meta={meta}
          hideActions={isProjectManager}
          className={
            showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
          }
        />
      </div>
  )
}
export default User;
