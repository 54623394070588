import { useEffect, useState } from 'react';

const Question = ({
  index,
  answer,
  startText,
  endText,
  onChange,
  type,
  ...question
}) => {
  const [isTaken, setIsTaken] = useState(question?.isTaken || false);

  const updateTooltip = () => {
    let thumbnWidth = 14;
    const range = document.querySelector('#range-slider');
    const tooltip = document.querySelector('#range-tooltip');

    const minValue = 1;
    const maxValue = 10;
    let offset = range.clientWidth / (maxValue - minValue);

    // Adjust the range value to 1 if the actual value is 0
    let rangeValue = range.valueAsNumber === 0 ? 1 : range.valueAsNumber;

    let left =
      (rangeValue - minValue) * offset -
      ((rangeValue - minValue) / (maxValue - minValue) - 0.5) * thumbnWidth;

    tooltip.style.setProperty('--left', `${left - 14}px`);
    tooltip.textContent = rangeValue;
  };

  const handleChange = (e) => {
    onChange(e.target.value);
    if (type === 'range') {
      updateTooltip();
    }
    setIsTaken(true);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) e.target.value = 0;
    else e.target.value = 1;
    handleChange(e);
  };

  useEffect(() => {
    if (type === 'range') {
      updateTooltip();
    }
  }, [index, type, answer]);

  return (
    <div>
      <h5 className="font-medium text-sm md:text-lg mb-20">
        {index + 1}. {question?.title}
      </h5>
      <div>
        <div className="flex relative items-start">
          <div
            className={`min-w-max pr-10 text-sm md:text-lg font-medium flex items-center ${
              answer === 0 && 'opacity-50'
            }`}
          >
            <span>{question?.property?.startText}</span>
          </div>

          {type === 'range' && (
            <div className="pt-1 w-full">
              <div className="relative">
                <span id="range-tooltip">1</span>
                <input
                  type="range"
                  step={1}
                  disabled={answer === 0}
                  className="bg-transparent range-slider disabled:opacity-50 disabled:pointer-events-none"
                  id="range-slider"
                  onChange={handleChange}
                  min={1}
                  max={10}
                  value={answer}
                />
              </div>
              <div
                className={`w-full flex justify-between  font-semibold ${
                  answer === 0 && 'opacity-50'
                }`}
              >
                <span className="block font-medium">1</span>
                <span className="block font-medium">10</span>
              </div>
            </div>
          )}

          {type === 'yes/no' && (
            <div className={`pt-1 w-full ${answer === 0 && 'opacity-50'}`}>
              <div id="group1" className="flex flex-row gap-3 justify-center">
                <label className="flex cursor-pointer gap-2">
                  <input
                    type="radio"
                    disabled={answer === 0}
                    className="radio radio-success"
                    name="group1"
                    onChange={handleChange}
                    checked={isTaken && answer === 10}
                    value="10"
                  />
                  <span>Ja</span>
                </label>
                <label className="flex cursor-pointer gap-2">
                  <input
                    type="radio"
                    disabled={answer === 0}
                    className="radio radio-error"
                    name="group1"
                    onChange={handleChange}
                    checked={isTaken && answer === 1}
                    value="1"
                  />
                  <span>Nee</span>
                </label>
              </div>
            </div>
          )}

          {type === 'multiple' && question?.property?.choices?.length > 0 && (
            <div className={`pt-1 w-full ${answer === 0 && 'opacity-50'}`}>
              <ul
                className="flex flex-wrap gap-6 pl-[2px] max-h-44 overflow-y-auto"
                id="choice-select"
              >
                {question?.property?.choices.map((choice) => (
                  <li className="mb-2 flex items-center gap-2" key={choice}>
                    <input
                      id={choice}
                      name="choice-select"
                      type="radio"
                      className="radio radio-secondary"
                      disabled={answer === 0}
                      onChange={handleChange}
                      value={choice}
                      checked={isTaken && answer === choice}
                    />
                    <label
                      htmlFor={choice}
                      className="cursor-pointer capitalize"
                    >
                      {choice}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div
            className={`min-w-max text-sm md:text-lg font-medium flex items-center pl-10 ${
              answer === 0 && 'opacity-50'
            }`}
          >
            <span>{question?.property?.endText}</span>
          </div>
        </div>

        <div className="flex items-center space-x-2 mt-5">
          <input
            id="custom-checkbox"
            type="checkbox"
            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
            onChange={handleCheckboxChange}
            checked={isTaken && answer === 0}
            value="0"
          />
          <label htmlFor="custom-checkbox" className="cursor-pointer">
            N.v.t
          </label>
        </div>
      </div>
    </div>
  );
};

export default Question;
