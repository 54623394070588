import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Config from "../Config";

export const AppContext = React.createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(undefined);
  const [surveyHistory, setSurveyHistory] = useState([]);

  useEffect(() => {
    async function fetchUser() {
        try {
          const token = localStorage.getItem("token");
          if(!token) return;

          Axios.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
          });

          const {data: { data}} = await Axios.get(`${Config.apiUrl}/api/v1/users/me`);
          setUser(data);
          setIsLoggedIn(true);
        } catch (error) {
           setIsLoggedIn(false);
           setUser(undefined);
        }
    }
    fetchUser();
  }, [])
  
  return <AppContext.Provider value={{isLoggedIn, setIsLoggedIn, user, surveyHistory, setSurveyHistory}}>
    {children}
  </AppContext.Provider>
}