import React, { useEffect, useState } from 'react';
import Config from '../Config';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../util/react-auth0-wrapper';

const SelectOrganization = () => {
  const [selectedOrganization, setSelectedOrganiation] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const history = useHistory();
  const { setAppUser } = useAuth0();

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const {
          data: { data },
        } = await Axios.get(`${Config.apiUrl}/api/v1/organizations`);
        setOrganizations(data);

        if (data?.length === 0) {
          history.push('/app/admin/organizations');
        }
      } catch (error) {
        toast('Failed to fetch organizations. Please try again later.', {
          type: 'error',
        });
      }
    };
    fetchOrganization();

    let organization = JSON.parse(
      localStorage.getItem('sa-organization') || '{}',
    );

    if (organization?.name) {
      setSelectedOrganiation(organization);
    }

    // eslint-disable-next-line
  }, []);

  const saveOrganization = (organization) => {
    localStorage.setItem('sa-organization', JSON.stringify(organization));
    setSelectedOrganiation(organization);
    setAppUser((prev) => ({ ...prev, organization }));

    if (history?.location?.state?.from) {
      history.goBack();
    } else {
      history.push('/app');
    }
  };

  return (
    <div className="h-screen pt-32 px-4">
      <h3 className="text-center font-bold text-3xl mb-20">
        Selecteer een organisatie
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 pb-20">
        {organizations?.map((organization) => (
          <div
            className={`card rounded-2xl p-5 cursor-pointer shadow-lg border flex justify-center items-center  ${
              selectedOrganization?._id === organization._id
                ? 'bg-gray-300'
                : 'bg-white'
            }`}
            onClick={() => saveOrganization(organization)}
            key={organization?._id}
          >
            <h1 className="text-center font-bold text-xl">
              {organization?.name}
            </h1>
            <p>Gebruikers: {organization?.users}</p>
            <p>Projecten: {organization?.projects}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectOrganization;
