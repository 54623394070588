const FinalQuestion = ({ topicsData, selectedSubTopics, onChange }) => {
  if (!topicsData || !Array.isArray(topicsData)) return;

  const isChecked = (value) => {
    if (!selectedSubTopics || !Array.isArray(selectedSubTopics)) return false;

    return selectedSubTopics.includes(value);
  };

  return (
    <div>
      <p>
        Welke vier van onderstaande factoren hebben jou geholpen er bovenop te
        komen tijdens heftige gebeurtenissen in het leven. Dit zijn jouw
        beschermende factoren.
      </p>
      <div className="flex justify-between flex-wrap gap-6 max-h-44 overflow-y-auto">
        {topicsData?.length > 0 &&
          topicsData.slice(0, 4).map((topic) => (
            <div className="p-6" key={topic?._id}>
              <p className="font-semibold text-sm md:text-lg">{topic?.title}</p>
              <ul>
                {topic?.subTopics?.length > 0 &&
                  topic?.subTopics.map((subTopic) => (
                    <li
                      className="mb-2 flex items-center gap-2"
                      key={subTopic?._id}
                    >
                      <input
                        id={subTopic?._id}
                        type="checkbox"
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
                        onChange={onChange}
                        value={subTopic?._id}
                        checked={isChecked(subTopic?._id)}
                      />
                      <label htmlFor={subTopic?._id} className="cursor-pointer">
                        {subTopic?.title}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FinalQuestion;
