import { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Config from '../../Config';
import Modal from './Modal';

const OnboardingUser = ({ currentUser, close }) => {
  const [onboardedData, setOnboardedData] = useState({
    firstName: '',
    postalCode: '',
    gender: '',
    yob: '',
    height: '',
    weight: '',
  });

  const onInputChange = (event) => {
    const name = event.target.name;

    if (name) {
      let value = event.target.value;
      setOnboardedData({ ...onboardedData, [name]: value });
    }
  };

  const onboardUser = async (event) => {
    event.preventDefault();

    if (!currentUser) {
      toast('Cannot onboard a non-existing user', { type: 'error' });
      return;
    }

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/users/${currentUser?._id}/onboard`,
        {
          ...onboardedData,
          onboarded: true,
        },
      );

      toast('Bedankt voor het invullen van je gegevens');
      close();
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  return (
    <Modal title="" action="" close={() => close()}>
      <form className="flex flex-col gap-4" onSubmit={onboardUser}>
        <p>We willen graag wat informatie over je hebben</p>
        <input
          name="firstName"
          className="w-full text-black rounded input bg-transparent"
          placeholder="Je voornaam"
          onChange={onInputChange}
          required
        />
        <input
          name="postalCode"
          className="w-full text-black rounded input bg-transparent"
          placeholder="Je postcode"
          onChange={onInputChange}
          required
        />

        <select
          className="select rounded min-w-full"
          name="gender"
          onChange={onInputChange}
          required
        >
          <option value="">Je geslacht</option>
          <option value="man">Man</option>
          <option value="vrouw">Vrouw</option>
          <option value="andere">Andere</option>
        </select>

        <input
          name="yob"
          className="w-full text-black rounded input bg-transparent"
          placeholder="Je geboortejaar"
          onChange={onInputChange}
          required
        />

        <input
          name="height"
          type="number"
          className="w-full text-black rounded input bg-transparent"
          placeholder="Lichaamslengte in cm"
          onChange={onInputChange}
          required
        />

        <input
          name="weight"
          type="number"
          className="w-full text-black rounded input bg-transparent"
          placeholder="Gewicht in kg"
          onChange={onInputChange}
          required
        />

        <div className="flex justify-end">
          <button type="submit" className="auth-button w-[100px]">
            Opslaan
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OnboardingUser;
